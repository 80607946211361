.filter-bottom-sheet {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  justify-content: space-around;
  transform: translateY(100%);
  z-index: 10001;
  padding: 20px;
  background-color: white;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}
.filter-bottom-sheet > div {
  cursor: pointer;
}
.filter-bottom-sheet .active {
  color: #ff2a2a;
}
.filter-bottom-sheet .item {
  background-color: #fff;
  border: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: none;
  line-height: 48px;
  padding: 0 16px;
  border-radius: 0;
  outline: none;
  font-size: 1.2rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .bottom-sheet-wrapper {
    display: none;
  }
}
.bottom-sheet-open {
  transform: translateY(0);
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  visibility: visible;
}

.bottom-sheet-close {
  transform: translateY(100%);
  visibility: visible;
}