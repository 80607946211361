@charset "UTF-8";
.container {
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
}
.container:before {
  display: table;
  content: "";
}
.container:after {
  display: table;
  content: "";
  clear: both;
}

.page-min-height {
  min-height: calc(100vh - 160px);
}

.cursor_pointer {
  cursor: pointer;
}

.clear {
  clear: both;
}

.pd-none {
  padding: 0 !important;
}

.clearfix::before {
  content: " ";
  display: table;
}
.clearfix::after {
  clear: both;
  content: " ";
  display: table;
}

.zero-padding {
  padding: 0 !important;
}

.japanese_gothic {
  font-family: "Source Han Sans", "源ノ角ゴシック", "Hiragino Sans", "HiraKakuProN-W3", "Hiragino Kaku Gothic ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Noto Sans", "Noto Sans CJK JP", "メイリオ", Meiryo, "游ゴシック", YuGothic, "ＭＳ Ｐゴシック", "MS PGothic", "ＭＳ ゴシック", "MS Gothic", sans-serif;
}

strong, b {
  font-weight: bold;
  line-height: inherit;
}

.dictionary_entry {
  margin: 0;
}
.dictionary_entry dt {
  display: inline;
}
.dictionary_entry dd {
  display: inline;
}

ul, ol, dl {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.6;
  list-style-position: outside;
  margin-bottom: 1.25rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

.ui .menu {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.ui .menu .item {
  outline: none;
}

.detail-panel {
  margin-left: -1rem;
  margin-right: -1rem;
}
.detail-panel .blank-content {
  padding: 10px 10px 10px 15px;
}
.detail-panel .pointing {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
}
.detail-panel .pointing .active {
  border-bottom: 2px solid rgb(204, 51, 77) !important;
}
.detail-panel .pointing .item {
  text-align: center;
  justify-content: center;
}
.detail-panel.tab-1 .item {
  width: 100% !important;
}
.detail-panel.tab-2 .item {
  width: 50% !important;
}
.detail-panel.tab-3 .item {
  width: 33.3333% !important;
}
.detail-panel.tab-4 .item {
  width: 25% !important;
}
.detail-panel .panel-content {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.functional_icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.jdict_scrollbar::-webkit-scrollbar-thumb {
  background: #fe4e4e;
  border-radius: 50px;
}
.jdict_scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 10px;
}

@media only screen and (min-width: 768px) {
  .concept_light-wrapper {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    width: 25%;
    float: left;
  }
  .column, .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
}
@media only screen and (min-width: 768px) {
  .column, .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .medium-9 {
    width: 75%;
  }
}
.column + .column:last-child, .columns + .column:last-child, .column + .columns:last-child, .columns + .columns:last-child {
  float: right;
}

.concept_light-wrapper {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  float: left;
}

.concept_light-readings .concept_light-representation:first-child {
  font-size: 1.3em;
}

.concept_light-readings .concept_light-representation {
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}

.concept_light-readings .concept_light-representation .text {
  display: block;
  line-height: 1.1;
}

.column, .columns {
  position: relative;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  float: left;
}

.column, .columns {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.concept_light-readings {
  font-size: 24px;
  margin-bottom: 10px;
  padding-left: 0;
}

.concept_light-wrapper {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  float: left;
}

.concept_light {
  padding: 0;
  margin: 0 0 20px 0;
}

.column, .columns {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  float: left;
}

.concept_light-meanings {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0 0 0 3px;
}

.concept_light-meanings .meanings-wrapper {
  margin: 0;
}

.concept_light-meanings .meaning-tags {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  display: inline-block;
  margin: 5px 0px;
  background: #f1f1f1;
  padding: 3px 10px;
  border-radius: 2px;
}

.concept_light-wrapper.concept_light-long_representation {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  float: left;
}

.light-details_link {
  display: block;
  float: right;
  font-size: 12px;
  padding: 3px 3px 2px 3px;
  opacity: 0.9;
  cursor: pointer;
  text-decoration: underline;
  color: #4A80B5;
}
.light-details_link:hover {
  color: black;
}

.jdict_result_list {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 1px 8px 0;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(225, 229, 229);
  border-image: initial;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* Track */
  /* Handle */
}
.jdict_result_list::-webkit-scrollbar {
  width: 3px;
}
.jdict_result_list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}
.jdict_result_list::-webkit-scrollbar-thumb {
  background: #fe4e4e;
  border-radius: 50px;
}

.jdict_result_item {
  background-color: #fff;
  padding: 8px 13px;
  position: relative;
  cursor: pointer;
}
.jdict_result_item:not(:last-child) {
  border-bottom: 1px solid #f1f3f4;
}
.jdict_result_item:hover, .jdict_result_item--active {
  background-color: #f1f3f4;
}
.jdict_result_item__concept {
  font-size: 17px;
  line-height: 30px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jdict_result_item__meaning {
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jdict_result_item__level {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 8px;
  background-color: #F47D7D;
  color: #fff;
  text-transform: uppercase;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  font-size: 1.5rem;
  color: #333;
  overflow-y: auto;
  overflow-x: hidden;
}

.vietnamese-font {
  font-family: "Roboto", sans-serif !important;
}

#nprogress .spinner {
  display: none !important;
}

.japanese-font {
  font-family: "Noto Sans JP", sans-serif !important;
}

.pr-4 {
  padding-right: 4rem;
}

.mt-1r {
  margin-top: 1rem;
}

.mt-2r {
  margin-top: 2rem !important;
}

input:focus {
  outline: none;
}

a {
  background-color: transparent;
  transition: all 0.1s;
}

.Toastify__toast-body {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }
  .mobile-space {
    padding-top: 40px;
  }
  .mobile-top-margin {
    margin-top: 9rem !important;
  }
  .desktop-only {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
  .desktop-only {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .hiden-tablet {
    display: none !important;
  }
}
.Toastify__toast--success {
  border-radius: 10px;
  background: #2db67c;
}

.Toastify__toast--error {
  border-radius: 10px;
}

.top-negative-1r {
  margin-top: -1rem !important;
}